import React from 'react';

class AssignedView extends React.Component {
    render() {
        return (
            <div className='assignedView'>
                <div className='personSkeleton' />
                <div className='personSkeleton' />
                <div className='personSkeleton' />
            </div>
        );
    }
}

export default AssignedView;
